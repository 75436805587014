import React, { FC, useRef } from 'react';
import bioimage from '../resources/images/home-img.jpeg';
import strings from '../resources/strings/home.json';
import styles from "../styles/Home.module.scss";
import {Box} from '@mui/material';

interface HomeProps { }

const Home: FC<HomeProps> = () => {
    const nodeRef = useRef(null);

    return (
        <div className={styles.Home} data-testid="Home">
            <picture className={styles.BackgroundImg}>
                <img src={bioimage} alt=""></img>
            </picture>
            <Box ref={nodeRef} className={styles.Bio} sx={{display: { xs: 'flex', md: 'none' }}}>
                <div className={styles.HomeCircle}>
                    <p>{strings.bio.name}</p>
                </div>
                <h4 className={styles.Title}>{strings.bio.title}</h4>
                <div className={styles.BioContent}>
                    <ul className={styles.List}>
                        <li>{strings.bio.bullet_1}</li>
                        <li>{strings.bio.bullet_2}</li>
                        <li>{strings.bio.bullet_3}</li>
                    </ul>
                </div>
            </Box>
        </div>
    )
};

export default Home;

import Home from './presentation/Home/Home.tsx';
import Navbar from './presentation/Navbar/Navbar.tsx';
import './presentation/styles/App.scss';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header"> */}
        <Navbar />
        <Home />
      {/* </header> */}
    </div>
  );
}

export default App;
